
import { AuthState } from '@/store/modules/auth/AuthModule'
import { BusinessClientInterface } from '@/store/types/Business/Business'
import { Component, Vue } from 'vue-property-decorator'
import jwt_decode from 'jwt-decode'
import localVuexUser from '@/utils/defaultValues'
import OverlayBackground from '@/components/OverlayBackground/Index.vue'
import type { ConfigCompanyEnable } from '@/utils/ConfigCompaniesEnabled'
import { useValidationTermsAndConditions, termsAndConditionsKey } from '@/utils/useValidationTermsAndConditions';

@Component({
  components: { OverlayBackground },
})
export default class CallbackView extends Vue {
  private USER = {
    METADATA: 'https://api-dev.hackmetrix.com/auth/metadata',
    ROLES: 'https://api-dev.hackmetrix.com/auth/roles',
  }

  private redirectPath: string | null = ''
  private authToken: string | (string | null)[] = ''
  private redirectPathTrue = ''
  private loading = false
  private implementationCompleted = false
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(',') || []

  private async getUserData() {
    this.loading = true

    let userData: AuthState
    this.authToken = this.getLargeToken('access_token_hm')
    const roles: any = jwt_decode(`${this.authToken}`)

    this.$store.dispatch('NotificactionsModule/OpenNotification', {
      message: 'Por favor espere, Iniciando sesión',
    })

    userData = {
      email: '',
      role: '',
      token: `Bearer ${this.authToken}`,
      isLogged: true,
      company_id: 0,
      company_name: '',
      folder_id: 0,
      name: '',
      last_name: '',
      id: 0,
      intercom_hmac_code: '',
      companySelected: { id: '', name: '', frameworks: [], folder: { id: 0 } },
    }

    this.$store.commit('AuthModule/SET_USER_AUTH', userData)

    await this.$store
      .dispatch('AuthModule/Login')
      .then((res) => {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Bienvenido a Hackmetrix App',
        })

        userData.intercom_hmac_code = res.headers['intercom-hmac-code']

        if (res.data.user.company) {
          userData.company_id = res.data.user.company.id
          userData.folder_id = res.data.user.company.folder_id
          userData.company_name = res.data.user.company.name
        }

        userData.role = roles.rolesHM
        userData.name = res.data.user.name
        userData.last_name = res.data.user.last_name
        userData.email = res.data.user.email
        userData.id = res.data.user.id

        this.$store.commit('AuthModule/SET_USER_AUTH', userData)
        if (!this.ADMIN_ROLES.some((adminRole: any) => userData.role.includes(adminRole))) {
          /* this.$mixpanel.init(
            'f82db623767e9e24246fe48f647f5e27',
            { debug: true, },
            'Hackmetrix (cliente) '
          ) */
          this.implementationCompleted = res?.data?.user?.company?.implementation_completed || false
          localStorage.setItem(
            'implementationCompleted',
            JSON.stringify(this.implementationCompleted || false)
          )
          const company: BusinessClientInterface = {
            id: res.data.user.company.id,
            name: res.data.user.company.name,
            folder_id: res.data.user.company.folder_id,
          }

          this.$mixpanel.identify(res.data.user.email)

          this.$mixpanel.track('Inicio Sesion', {
            $email: res.data.user.email,
            $company: company.name,
          })

          this.$mixpanel.people.set_once({
            $name: userData.name,
            $last_name: userData.last_name,
            $email: res.data.user.email,
            $company: company.name,
          })

          this.$store.commit('BusinessModule/SET_BUSINESS_CLIENT', company)
          const isNewCompany = this.validNewCompany();
          if (isNewCompany) {
            window.location.replace(process.env.VUE_APP_URL_MF_REDIRECT || "");
          }

          this.$store.dispatch('BusinessModule/getBusinessDetail', company.id).then(() => {
            this.$store.commit(
              'PolimorficModule/SET_FRAMEWORK_SELECTED',
              this.$store.state.BusinessModule.BusinessState.businessSelected.company.frameworks[0]
            )
            this.loading = false
            this.redirect(userData)
          })
        } else {
          /* this.$mixpanel.init(
            '17308baf4a16c6e24c4eb05ad23f3c98',
            { debug: true, },
            'Hackmetrix (Dev) '
          ) */
          this.$mixpanel.identify(res.data.user.email)
          this.$mixpanel.track('Sesion Iniciada', { email: res.data.user.email })
          this.$mixpanel.people.set_once({
            Rol: res.data.user.role,
            $name: userData.name,
            $last_name: userData.last_name,
            $email: res.data.user.email,
            $company: 'Hackmetrix',
          })
          this.loading = false

          this.redirect(userData)
        }
      })
      .catch((error) => {
        this.$store.commit('AuthModule/SET_TOKEN_AUTH')
        window.location.replace(`${process.env.VUE_APP_AUTH_BASE_URL}/logout`)
      })
  }

  private login() {
    this.loading = true
    this.$store.commit('AuthModule/SET_TOKEN_AUTH')
    window.location.replace(`${process.env.VUE_APP_BASE_URL}/callback`)
    this.loading = false
  }

  private validNewCompany() {
    const user: any = jwt_decode(`${this.authToken}`);
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(user.metadata.companyId);
  }

  private redirect(userData: any) {
    if (this.redirectPath) {
      this.redirectPathTrue = this.redirectPath
    } else {
      if (
        userData.role?.includes('Admin CMS') ||
        userData.role?.includes('Implementador') ||
        userData.role?.includes('Customer Success')
      ) {
        this.redirectPathTrue = 'app/clients'
      } else {
        this.redirectPathTrue = 'startup/activity'
      }
    }
    this.redirectPath = ''
    localStorage.removeItem('fullPath')

    if (
      userData.role?.includes('Admin CMS') ||
      userData.role?.includes('Implementador') ||
      userData.role?.includes('Customer Success')
    ) {
      this.$router.push(this.redirectPathTrue).catch((error) => {
        if (error.name !== 'NavigationDuplicated') return
      })
    } else if (
      this.implementationCompleted &&
      !this.ADMIN_ROLES.some((adminRole: any) => userData.role.includes(adminRole))
    ) {
      useValidationTermsAndConditions();
      this.$mixpanel.track('Redirección home a Centro de Mando')
      window.location.replace(process.env.VUE_APP_URL_COMMAND_CENTER || '')
    } else {
      useValidationTermsAndConditions();
      this.$router.push(this.redirectPathTrue)
    }
  }
  getLargeToken(cookieName: any) {
    let token = ''
    let index = 0
    while (true) {
      const chunk = document.cookie.match(new RegExp(`(^| )${cookieName}_${index}=([^;]+)`))
      if (!chunk) break
      token += chunk[2]
      index++
    }
    return token
  }

  async mounted() {
    this.loading = true
    const configCompany: string = process.env.VUE_APP_COMPANIES_ENABLED || ''
    const config: ConfigCompanyEnable[] = JSON.parse(configCompany)
    localStorage.setItem('enabledCompanies', JSON.stringify(config))

    if (process.env.VUE_APP_CONTEXT != 'local') {
      const token = this.getLargeToken('access_token_hm')
      if (token) {
        this.getUserData()
        this.loading = false
      } else {
        this.login()
        this.loading = false
      }
    } else {
      this.loading = false

      this.$store.commit('AuthModule/SET_USER_AUTH', localVuexUser)
      this.redirect(localVuexUser)
    }
  }
}
