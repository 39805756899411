import { InjectionKey } from 'vue';
import store from '@/store/index';
import { AcceptedTermsInterface, TermAndConditionsInterface } from '@/store/types/Settings/TermsAndConditions';

function checkTermsAndRedirect(
  termsAndConditions: TermAndConditionsInterface[],
  acceptedTerms: AcceptedTermsInterface[],
  featureFlagEnabled: boolean
) {
  const latestTerm = termsAndConditions.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )[0];

  if (!latestTerm) {
    console.error("No hay términos y condiciones disponibles");
    return;
  }

  const isLatestTermAccepted = acceptedTerms.some(term => term.termsConditions?.guid === latestTerm.guid);

  if (featureFlagEnabled) {
    if (!isLatestTermAccepted) {
      localStorage.setItem("termNotAccepted", JSON.stringify(latestTerm));
      window.location.replace("/termsandconditions");
    } 
  } 
}

export const useValidationTermsAndConditions = async () => {
  const featureFlagTerms = localStorage.getItem("termsAndConditions");
  const parsedFeatureFlag = featureFlagTerms ? JSON.parse(featureFlagTerms) : null;

  try {
    await store.dispatch('SettingsModule/getCompanyTermsAndConditions');
  } catch (error) {
    console.error("Error al obtener términos y condiciones de la empresa:", error);
    return { error };
  }

  const findTermsAndConditions = store.getters['SettingsModule/getTermsAndConditions'];

  const { acceptedTerms, termsAndConditions } = findTermsAndConditions;

  if (!termsAndConditions || termsAndConditions.length === 0) {
    return { findTermsAndConditions };
  }

  const companyId = store.state.AuthModule.AuthState.company_id;

  const isCompanyInFeatureFlag =
    parsedFeatureFlag && parsedFeatureFlag.enabledCompanyIds.includes(companyId);


  checkTermsAndRedirect(termsAndConditions, acceptedTerms, isCompanyInFeatureFlag);

  return {
    findTermsAndConditions,
  };
};

export type TermsAndConditionsComposable = ReturnType<typeof useValidationTermsAndConditions>;

export const termsAndConditionsKey: InjectionKey<TermsAndConditionsComposable> = Symbol('termsAndConditions');
